// Generated by Framer (461de17)

import { addFonts, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {TCQHSGvab: {pressed: true}};

const cycleOrder = ["TCQHSGvab"];

const serializationHash = "framer-hvG4I"

const variantClassNames = {TCQHSGvab: "framer-v-dvywpj"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "TCQHSGvab", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-dvywpj", className, classNames)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"TCQHSGvab"} ref={ref ?? ref1} style={{backdropFilter: "blur(10px)", backgroundColor: "var(--token-af4e8e8f-10c6-4ae5-ab1e-3f2d991ffdc2, rgba(13, 13, 13, 0.3))", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, WebkitBackdropFilter: "blur(10px)", ...style}} {...addPropertyOverrides({"TCQHSGvab-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-hvG4I.framer-iqv2qi, .framer-hvG4I .framer-iqv2qi { display: block; }", ".framer-hvG4I.framer-dvywpj { cursor: pointer; height: 20px; overflow: hidden; position: relative; width: 20px; will-change: var(--framer-will-change-override, transform); }", ".framer-hvG4I.framer-v-dvywpj.pressed.framer-dvywpj { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 16px); width: 16px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 20
 * @framerIntrinsicWidth 20
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"c298EREms":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerJRhVqE77l: React.ComponentType<Props> = withCSS(Component, css, "framer-hvG4I") as typeof Component;
export default FramerJRhVqE77l;

FramerJRhVqE77l.displayName = "Elements/Cursor";

FramerJRhVqE77l.defaultProps = {height: 20, width: 20};

addFonts(FramerJRhVqE77l, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})